<div class="code-box">
  <div class="custom-container">
    <p class="code-enhance-header">BRD module effortlessly create Business Requirement Documents, streamlining project initiation.
      Save time and reduce miscommunication, enhancing productivity from project kick-off.</p>
    <p class="code-enhance-desc"></p>
    <div class="form-box">
      <form [formGroup]="brdForm" (ngSubmit)="onButtonSubmit()">
        <div class="row gx-2 gy-2">
          <div class="col-md-6">
            <div class="form-group">
              <label for="exampleInputEmail1" class="astreik">Process name:</label>
              <textarea class="form-control resizer-none" rows="4" formControlName="process_name"
                placeholder="Please provide a concise and descriptive name for the process that accurately reflects its purpose and function. The process name should be clear and easily understandable, giving a quick overview of what the process entails. "></textarea>
              <div class="text-danger"
                *ngIf="brdForm.get('process_name')?.invalid && (brdForm.get('process_name')?.dirty || brdForm.get('process_name')?.touched)">
                <small *ngIf="brdForm.get('process_name')?.hasError('required')">
                  Process name is required.
                </small>
                <small *ngIf="brdForm.get('process_name')?.hasError('minlength')">
                  process name can not be less then 5
                </small>
                <small *ngIf="brdForm.get('process_name')?.hasError('maxlength')">
                  process name can not be greater than 20.
                </small>
              </div>
            </div>

          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="exampleFormControlSelect1" class="astreik">Preferred Tech Stack
:</label>
              <mat-select class="form-control" formControlName="coding_language"
                style="padding: 0.375rem 10px 0.375rem 2px; margin-top:4px;">
                <mat-option value="Python">Python</mat-option>
                <mat-option value="VBA">VBA</mat-option>
              </mat-select>
              <div class="text-danger"
                *ngIf="brdForm.get('coding_language')?.invalid && (brdForm.get('coding_language')?.dirty || brdForm.get('coding_language')?.touched)">
                <small *ngIf="brdForm.get('coding_language')?.hasError('required')">
                  Preferred Tech Stack
 is required.
                </small>
              </div>
            </div>
          </div>
        </div>

        <div class="row gx-2 gy-2">
          <div class="col">
            <div class="form-group">
              <label for="exampleFormControlTextarea1" class="astreik">Project description:</label>
              <textarea class="form-control resizer-none" rows="5" formControlName="project_description"
                placeholder="In this section, provide a comprehensive overview of the entire project. Include the project’s goals, objectives, and any relevant background information. Describe the context in which the project will be executed, detailing its significance and potential impact on the organization."></textarea>
              <div class="text-danger"
                *ngIf="brdForm.get('project_description')?.invalid && (brdForm.get('project_description')?.dirty || brdForm.get('project_description')?.touched)">
                <small *ngIf="brdForm.get('project_description')?.hasError('required')">
                  Project description is required.
                </small>
                <small *ngIf="brdForm.get('project_description')?.hasError('minlength')">
                  Project description can not be less then 25
                </small>
                <small *ngIf="brdForm.get('project_description')?.hasError('maxlength')">
                  Project description can not be greater than 7500.
                </small>
              </div>
            </div>
          </div>
        </div>

        <div class="row gx-2 gy-2">
          <div class="col-md-6">
            <div class="form-group">
              <label for="exampleFormControlTextarea1" class="astreik">Automation objective:</label>
              <textarea class="form-control resizer-none" rows="5" formControlName="automation_objective"
                placeholder="Outline the specific objectives and goals of automating the identified process. Clearly define what you aim to achieve through automation, such as increased efficiency, reduced manual effort, or improved accuracy. Be explicit about the desired outcomes to ensure a focused and effective automation strategy."></textarea>
              <div class="text-danger"
                *ngIf="brdForm.get('automation_objective')?.invalid && (brdForm.get('automation_objective')?.dirty || brdForm.get('automation_objective')?.touched)">
                <small *ngIf="brdForm.get('automation_objective')?.hasError('required')">
                  Automation objective is required.
                </small>
                <small *ngIf="brdForm.get('automation_objective')?.hasError('minlength')">
                  Automation objective can not be less then 25
                </small>
                <small *ngIf="brdForm.get('automation_objective')?.hasError('maxlength')">
                  Automation objective can not be greater than 7500.
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="exampleFormControlTextarea1" class="astreik">Project Scope:</label>
              <textarea class="form-control resizer-none" rows="5" formControlName="project_scope"
                placeholder="Define the boundaries and limitations of the project. Clearly state what is included within the Project Scope and what is not. Consider factors such as time constraints, resource availability, and any external dependencies. Providing a well-defined Project Scope will help manage expectations and ensure a focused and successful project execution."></textarea>
              <div class="text-danger"
                *ngIf="brdForm.get('project_scope')?.invalid && (brdForm.get('project_scope')?.dirty || brdForm.get('project_scope')?.touched)">
                <small *ngIf="brdForm.get('project_scope')?.hasError('required')">
                  Project Scope is required.
                </small>
                <small *ngIf="brdForm.get('project_scope')?.hasError('minlength')">
                  Project Scope can not be greater than 5000.
                </small>
              </div>
            </div>
          </div>
        </div>

        <div class="row gx-2 gy-2">
          <div class="col">
            <div class="form-group">
              <label for="exampleFormControlTextarea1" class="astreik">Present Process:</label>
              <textarea class="form-control resizer-none" rows="5" formControlName="present_process"
                placeholder="Describe the current manual or automated process that you intend to improve or replace. Include key steps, stakeholders involved, and any pain points or inefficiencies you have observed. This will provide a baseline understanding of the existing process and the areas that require enhancement."></textarea>
              <div class="text-danger"
                *ngIf="brdForm.get('present_process')?.invalid && (brdForm.get('present_process')?.dirty || brdForm.get('present_process')?.touched)">
                <small *ngIf="brdForm.get('present_process')?.hasError('required')">
                  Present Process is required.
                </small>
                <small *ngIf="brdForm.get('present_process')?.hasError('minlength')">
                  Present Process can not be less then 2
                </small>
                <small *ngIf="brdForm.get('present_process')?.hasError('maxlength')">
                  Present Process can not be greater than 2500.
                </small>
              </div>
            </div>
          </div>
        </div>

        <div class="row gx-2 gy-2">
          <div class="col">
            <div class="form-group">
              <label for="exampleFormControlTextarea1" class="astreik">Proposed Process:</label>
              <textarea class="form-control resizer-none" rows="5" formControlName="proposed_process"
                placeholder="Outline the envisioned automated process. Specify the steps involved, the technologies or tools to be used, and how the Proposed Process differs from the current one. Emphasize the improvements and efficiencies expected, ensuring a clear understanding of the changes you plan to implement."></textarea>
              <div class="text-danger"
                *ngIf="brdForm.get('proposed_process')?.invalid && (brdForm.get('proposed_process')?.dirty || brdForm.get('proposed_process')?.touched)">
                <small *ngIf="brdForm.get('proposed_process')?.hasError('required')">
                  Proposed Process is required.
                </small>
                <small *ngIf="brdForm.get('proposed_process')?.hasError('minlength')">
                  Proposed Process can not be less then 5
                </small>
                <small *ngIf="brdForm.get('proposed_process')?.hasError('maxlength')">
                  Proposed Process can not be greater than 12500.
                </small>
              </div>
            </div>
          </div>
        </div>

        <div class="btn-submit">
          <app-button [buttonClass]="'BRD-btn'" [buttonText]="'Generate BRD'"></app-button>
          <!-- <a *ngIf="showGoToSDD" class="goToLink" (click)="goToSDD()">Go To SDD</a> -->
          <!-- <a class="goToLink" (click)="goToSDD()">Go To SDD</a> -->
          <button *ngIf="showGoToSDD" class="btn-navigate" (click)="goToSDD()">Next: Go to SDD</button>
        </div>
      </form>
    </div>
  </div>
</div>
