import { Injectable } from '@angular/core';
import { marked } from 'marked';  // Markdown to HTML conversion
import * as docx from 'docx';  // DOCX generation library
import { saveAs } from 'file-saver';  // For saving the file

@Injectable({
  providedIn: 'root'
})
export class DocxExportService {

  constructor() {}

  /**
   * Downloads the provided Markdown content as a DOCX file.
   * @param markdownContent - The Markdown content to be included in the DOCX.
   * @param fileName - The desired name for the downloaded file.
   */
  async downloadMarkdownAsDocx(markdownContent: string, fileName: string): Promise<void> {
    try {
      // Parse Markdown to get formatted elements
      const parsedContent = marked.parse(markdownContent, { gfm: true, breaks: true });

      // Create a new DOCX document
      const doc = new docx.Document({
        sections: [
          {
            properties: {},
            children: this.convertMarkdownToDocxElements(parsedContent),
          },
        ],
      });

      // Generate the DOCX file as a Blob
      const buffer = await docx.Packer.toBlob(doc);

      // Save the DOCX file with the provided file name
      saveAs(buffer, fileName);
    } catch (error) {
      console.error('Error generating DOCX:', error);
    }
  }

  /**
   * Converts parsed Markdown content to DOCX elements.
   * @param parsedContent - The parsed Markdown content.
   * @returns array - The DOCX elements to include in the document.
   */
  private convertMarkdownToDocxElements(parsedContent: string): docx.Paragraph[] {
    // Create a temporary container to parse HTML to elements
    const tempElement = document.createElement('div');
    tempElement.innerHTML = parsedContent;
    const paragraphs: docx.Paragraph[] = [];

    // Iterate through each child node of the parsed content
    tempElement.childNodes.forEach((node) => {
      if (node.nodeName === 'H1') {
        paragraphs.push(new docx.Paragraph({ text: node.textContent || '', heading: docx.HeadingLevel.HEADING_1 }));
      } else if (node.nodeName === 'H2') {
        paragraphs.push(new docx.Paragraph({ text: node.textContent || '', heading: docx.HeadingLevel.HEADING_2 }));
      } else if (node.nodeName === 'H3') {
        paragraphs.push(new docx.Paragraph({ text: node.textContent || '', heading: docx.HeadingLevel.HEADING_3 }));
      } else if (node.nodeName === 'P') {
        paragraphs.push(new docx.Paragraph({ text: node.textContent || '' }));
      } else if (node.nodeName === 'UL') {
        node.childNodes.forEach((listItem) => {
          if (listItem.nodeName === 'LI') {
            paragraphs.push(new docx.Paragraph({
              text: listItem.textContent || '',
              bullet: { level: 0 },
            }));
          }
        });
      } else if (node.nodeName === 'OL') {
        let order = 1;
        node.childNodes.forEach((listItem) => {
          if (listItem.nodeName === 'LI') {
            paragraphs.push(new docx.Paragraph({
              text: `${order++}. ${listItem.textContent || ''}`,
            }));
          }
        });
      } else if (node.nodeName === 'STRONG') {
        paragraphs.push(new docx.Paragraph({
          children: [new docx.TextRun({ text: node.textContent || '', bold: true })],
        }));
      } else if (node.nodeName === 'EM') {
        paragraphs.push(new docx.Paragraph({
          children: [new docx.TextRun({ text: node.textContent || '', italics: true })],
        }));
      }
    });

    return paragraphs;
  }
}
