<div class="fine-tune-model-container" style="background-color: #EEF6F9; overflow-y: scroll;">

<div class="container" style="flex-direction: column;" *ngIf="filestatus">
  <!-- <div class="textarea" style="width:50%;">
    <h2 style="color:#073262;">User Requirements</h2>
    <textarea style="width:100%;    height: 300px;
    resize: none;
    overflow-y: scroll;" title="user req" [innerHTML]="stext"></textarea>
    <div class="text-center" >
      <input type="file" (change)="handleFileChange($event)" #fileInput style="display: none; height:40px;" inputAccept=".doc,.txt,.docx" >

      <button tooltip="Upload files "  style="border:none; height:40px;width:50%;border-radius:0px" (click)="fileInput.click()" type="button" id="yourButton" class="btn btn-primary px-2 py-1 btn-block">
        <span class="button-text">Upload BRD Document</span>
      </button>
      </div>
  </div> -->
  <div class="form-box">
<br>
<br>
    <div class="row gx-2 gy-2">
      <div class="csol">
        <p class="upload-label" class="astreik">Project Name:</p>

          <textarea [(ngModel)]="processName" [ngModelOptions]="{ standalone: true }"
          style="resize:none;height:50%; width:100%;" rows="4"
          placeholder="Please provide a concise and descriptive name for the process that accurately reflects its purpose and function."
          name="processName" #processNameField="ngModel" required minlength="5"  maxlength="255" ></textarea>

          <div class="text-danger">
            <!-- <small *ngIf="processNameField.errors?.required">Process name is required.</small> -->
            <small *ngIf="processNameField.errors?.['minlength']">Project Name cannot be less than 5 and cannot be greater than 255.

            </small>
            <small *ngIf="processNameField.errors?.['maxlength']">Project Name cannot be greater than 255.</small>
            <!-- <small *ngIf="processName.length >= 5">
              {{ 50 - processName.length }} characters left (max is 50).
            </small> -->
          </div>

      </div>
      </div>
      </div>

      <div class="csol">


        <p class="upload-label" class="astreik">Preferred Tech Stack
:</p>
        <div class="file-upload form-control" style="margin-bottom: 10px;" >

        <mat-select style="background-color:#ffffff;" [(ngModel)]="codelang" [ngModelOptions]="{ standalone: true }" placeholder="Please specify the Preferred Tech Stack
 for the project. This will help in identifying the most suitable resources and tools for the project." multiple>
            <mat-option *ngFor="let language of codingLanguages" [value]="language">{{ language }}</mat-option>
          </mat-select>
       </div>
</div>

  <div class="row mt-4" >

    <div class="col-lg-12">
  <div class="uploaded-file">
    <div >
  <div class="uploaded-file-section" >
    <div class="upload-file-name">
        <img src="../../../../../assets/images/codeDocument.png" alt="">
    <span tooltip="user requirement doc" class="file-font">{{ docname ? docname : 'SDD doc' }}</span>
    </div>
    <span class="action-buttons">
      <input type="file" (change)="handleFileChange($event)" #fileInput style="display: none;" accept=".txt,.docx">

      <img  tooltip="only .txt,.docx are allowed" src="../../../../../assets/images/uploadBlack.png" alt="" (click)="fileInput.click()">
</span>
<span class="action-buttons">
  <img tooltip="edit document text" src="../../../../../assets/images/refresh.png" alt="" data-toggle="modal" data-target="#myModal" >
    <!-- /<img src="../../../../../assets/images/delete.png" alt=""> -->

  </span>
</div>
</div>
</div>
</div>
</div>
  <!-- <div class="textarea" style="width:50%;">
    <h2 style="color:#073262;">API structure</h2>
    <textarea style="width:100%;    height: 300px; background-color:#181515;color:#ffffff;
    resize: none;
 overflow-y: scroll;" title="api" [(ngModel)]="stext1"></textarea>
   <div class="text-center" >
    <input type="file" (change)="handleFileInput($event)" #fileInput1 style="display: none; height:40px;" inputAccept=".doc,.txt" >

    <button tooltip="Upload files "  style="border:none; height:40px;width:50%;border-radius:0px" (click)="fileInput1.click()" type="button" id="yourButton" class="btn btn-primary px-2 py-1 btn-block">
      <span class="button-text">Upload API document</span>
    </button>
    </div>
  </div> -->
  <div class="row mt-4" >

    <div class="col-lg-12">
  <div class="uploaded-file">
    <div >
  <div class="uploaded-file-section" >
    <input style="    margin-left: -30px;
    border-radius: 0px;
    border: 1px solid #073262;
" title="api" tooltip="check this to submit api design doc " class="form-check-input" [(ngModel)]="apistatus"  type="checkbox"  id="style_guide">

    <div class="upload-file-name">

        <img src="../../../../../assets/images/codeDocument.png" alt="">
    <span tooltip="user requirement doc" class="file-font">{{ docname1 ? docname1 : 'API doc' }}</span>
    </div>
    <span class="action-buttons">
      <input type="file" (change)="handleFileInput($event)" #fileInput1 style="display: none;" accept=".txt,.docx">

      <img tooltip="only .txt,.docx are allowed" src="../../../../../assets/images/uploadBlack.png" alt="" (click)="fileInput1.click()">
</span>
<span class="action-buttons">
    <img  tooltip="edit document text" src="../../../../../assets/images/refresh.png" alt="" data-toggle="modal" data-target="#myModal1" >
    <!-- /<img src="../../../../../assets/images/delete.png" alt=""> -->

  </span>
</div>

</div>
</div>
    </div>
    </div>






</div>
<br>

<div class="text-center" style="padding-right: 10px; "  *ngIf="filestatus">
  <div class="text-center" >
    </div>
    <div  >
    <button
      class="BRD-btn"
      style=" color:#ffffff !important;border-radius:0px;"
      (click)="DocCnfrm()"
[disabled]="!stext || processName.length<5 "
    >
      Generate Code
    </button></div>
    </div>

<br><br><br>
<div class="fine-tune-model-container" *ngIf="!filestatus">
  <div class="chat-result-display-section">
    <div #messageContainer class="_react_scroll_to_bottom">
      <div class="chat-messages">

        <div class="user-message" *ngIf="userDocCnfrm">
          <div class="message-content">Confirm Interface Document</div>
          <fa-icon
            class="odnoklassnikicss"
            [icon]="odnoklassniki"
            size="2x"
          ></fa-icon>
        </div>

        <div class="gpt-message" *ngIf="this.workLocation != undefined">
          <fa-icon [icon]="redditIcon" class="redditcss" size="2x"></fa-icon>
          <div class="message-content">
            <div class="ai-code python_demo">
              <p>
                According to the above development tasks and the product
                database, I analyze that the following contents need to be
                modified:
              </p>
              <h4 class="ui horizontal divider header">
                <fa-icon [icon]="faCoffee" class="coffee"></fa-icon>
                Project Files
              </h4>
              <div class="ui yellow visible message">
                Operation:
                <button class="ui teal button tiny" (click)="pushCode()"
                style="color: #ffffff;
                background-color: #073262;">
                  <fa-icon [icon]="faTask" class="tasks icon"></fa-icon>Submit Code
                </button>
              </div>

              <table class="ui definition table" *ngFor="let data of gptCode, let i = index">
                <tbody id="task_tbod_kwrn0odu31699509962510">
                  <tr>
                    <td class="two wide column">Modify file</td>
                    <td>{{ data.filepath }}</td>
                  </tr>
                  <tr>
                    <td>Reason for modification</td>
                    <td>
                      <div [innerText]="data.code_interpreter"></div>
                    </td>
                  </tr>
                  <tr *ngIf="data.adjustCode != undefined">
                    <td>Adjust code</td>
                    <td>
                      <div [innerHTML]="data.adjustCode"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>
                      <button
                        class="ui grey button tiny" style="color:#ffffff; background-color:#1BA641; margin-right: 2px;"
                        *ngIf="data.IsAdjusted == true"
                      >
                        <fa-icon [icon]="faEdit" class="check icon"></fa-icon>
                        Adjusted
                      </button>
                      <button
                        class="ui circular check icon button task_status_button tiny"
                        style="color: #fff;
                        background-color: #1BA641;"
                        [ngClass]="data.code == undefined ? 'olive' : 'green'"
                        (click)="
                          selfCheckBtn(data, data.filepath, 'InitialCode')
                        "

                      >
                        <fa-icon
                          [icon]="data.code == undefined ? spinner : faCheck"
                          [spin]="data.code == undefined ? true : false"
                          class="check icon"
                        ></fa-icon>
                        Initial Code
                      </button>

                      <button
                        class="ui circular check icon button task_status_button tiny"
                        style="margin-left:2px!important;"
                        [ngClass]="
                          data.selfCheck.__zone_symbol__value.length == 0
                            ? 'olive'
                            : data.selfCheck.__zone_symbol__value.success ==
                              'success'
                            ? 'green'
                            : 'red'
                        "
                        (click)="
                          selfCheckBtn(
                            data.selfCheck.__zone_symbol__value,
                            data.filepath,
                            'SelfCheck'
                          )
                        "

                      >
                        <fa-icon
                          [icon]="
                            data.selfCheck.__zone_symbol__value.length == 0
                              ? spinner
                              : data.selfCheck.__zone_symbol__value.success ==
                                'success'
                              ? faCheck
                              : faClose
                          "
                          [spin]="
                            data.selfCheck.__zone_symbol__value.length == 0
                              ? true
                              : false
                          "
                          class="check icon"
                        ></fa-icon>
                        Self Check
                      </button>

                      <button
                        class="ui circular check green icon button task_status_button tiny"
                        style="color: #fff;
                        background-color: #1BA641;
                        margin-left:2px!important;
                        margin-top: 2px;"
                        (click)="
                          selfCheckBtn(
                            data.checklint.__zone_symbol__value,
                            data.filepath,
                            'StaticScan'
                          )
                        "
                        *ngIf="data.selfCheck.__zone_symbol__value.length != 0"
                      >
                        <fa-icon [icon]="faCheck" class="check icon"></fa-icon>
                        Static scan
                      </button>
                    </td>
                  </tr>

                  <tr>
                    <td>Operations</td>
                    <td>
                      <button
                        class="ui blue button tiny"
                        (click)="adjustCodebtn(data)"
                        style="color: #fff;
                        background-color: #007199;
                        "
                      >
                        <fa-icon [icon]="faEdit" class="check icon"></fa-icon>
                        Adjust Code
                      </button>
                      <button
                        class="ui orange button tiny"
                        (click)="EditFileTask(i)"
                        style="color: #fff;
                        background-color: #F1743C;
                        margin-left:2px!important"
                      >
                        <fa-icon [icon]="data.IsRestartLoading == true ? spinner : faPlay" [spin]="data.IsRestartLoading == true ? true : false" class="check icon"></fa-icon>
                        Restart
                      </button>
                      <button
                        class="ui brown button tiny"
                        (click)="reviewCode(data)"
                        style="color: #fff;
                        background-color: #b43b44;
                        margin-left:1px!important;
                        margin-top:1px!important;"
                      >
                        <fa-icon [icon]="faCode" class="check icon"></fa-icon>
                        Review Code
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!-- <div [class]="msg.msgClass" *ngFor="let msg of messages" [ngSwitch]="msg.msgIconClass">
          <fa-icon *ngSwitchCase="'redditcss'" [icon]="msg.msgIcon" [class]="msg.msgIconClass" size="2x"></fa-icon>
          <div class="message-content" [innerHTML]="msg.msgContent">
            <div [innerHTML]="msg.msgContent"></div>
            <button class="ui green button" (click)="msg.submitmethod" [disabled]="msg.SubmitBtnDisable" [hidden]="msg.submitHidden">Submit</button>
            <button class="ui green button" (click)="msg.editMethod" [disabled]="msg.EditBtnDisable" [hidden]="msg.editHidden">Edit</button>
          </div>
          <fa-icon *ngSwitchCase="'odnoklassnikicss'" [icon]="msg.msgIcon" [class]="msg.msgIconClass" size="2x"></fa-icon>
        </div> -->
      </div>

      <!-- EDIT CODE POPUP -->
      <!-- Button trigger modal -->
<button type="button" style="display: none;" id="hbtn3" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modal3" style="display: none;">
  Open Modal
</button>

<!-- Modal -->
<div class="modal fade" id="modal3" tabindex="-1" aria-labelledby="modal3Label" aria-hidden="true" *ngIf="codeEdit">
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modal3Label">Code Editor</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="editClose()"></button>
      </div>
      <div class="modal-body">
        <div id="diff-output">
          <pre [contentEditable]="EditConsoleEnable" id="code" [innerText]="codeData.updatedCode"></pre>
        </div>
      </div>
      <div class="modal-footer">
        <input type="hidden" id="code-actions-uuid" value="iw68pve7n1699601042887" />
        <!-- <button type="button" class="btn btn-warning" style="margin-left: 1px !important;" (click)="enableCodeEditor()" [hidden]="editBtnHidden">
          Edit
        </button>
        <button type="button" class="btn btn-warning" style="margin-left: 1px !important;" (click)="updateCode('', 'editCode')" [hidden]="!editBtnHidden">
          Update
        </button> -->
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="editClose()">Close</button>
      </div>
    </div>
  </div>
</div>


      <!-- SELF CHECK -->
      <button type="button" class="btn btn-primary" style="display: none;" id="hbtn" data-bs-toggle="modal" data-bs-target="#modal1">
        Open Modal
      </button>
      <div class="modal fade" id="modal1" tabindex="-1" aria-labelledby="modal1Label" aria-hidden="true" *ngIf="selfCheckPopup">
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modal1Label">{{ selfcheckFilepath }}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="selfCheckClose()"></button>
            </div>
            <div class="modal-body">
              <h4 [innerHTML]="selfCheckReason"></h4>
              <pre [innerText]="selfCheckCode"></pre>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="selfCheckClose()">Close</button>
            </div>
          </div>
        </div>
      </div>


      <!--  -->

      <!-- EDIT FILE TASK POPUP -->

    <!-- Button trigger modal -->
<!-- Button trigger modal -->
<button type="button" style="display: none;" id="hbtn2" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modal2">
  Open Modal
</button>

<!-- Modal -->
<div class="modal fade" id="modal2" tabindex="-1" aria-labelledby="modal1Label" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modal2Label">Modify File</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <!-- Your modal content goes here -->
        <div class="form-group">
          <label for="filePath">Modify file</label>
          <input type="text" class="form-control" id="filePath" [value]="editFileData?.filepath" />
        </div>
        <div class="form-group">
          <label for="reason">Reason for modification</label>
          <textarea class="form-control" id="reason" rows="4" [value]="editFileData?.code_interpreter"></textarea>
        </div>
        <div class="form-group">
          <label for="NewFileTask">New Task</label>
          <textarea class="form-control" id="NewFileTask" [(ngModel)]="newFileTask"></textarea>
          <span *ngIf="fileTaskError" style="color: red; font-size: smaller">*New task text is mandatory</span>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="adjustCodeSubmit(editFileData?.filepath)" data-bs-dismiss="modal">Submit</button>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>



      <!-- <div *ngIf="isLoading" class="LoadingDiv">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Generating results, please wait....
      </div> -->
    </div>
  </div>


</div>
<br><br><br>
<div *ngIf="isLoading" class="overlay">
  <div class="spinner"></div>
  <br>

</div>

</div>

<!-- modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel1">SDD doc</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="input-group input-group-lg">
          <textarea style="width:100%;    height: 300px;
          resize: none;
          overflow-y: scroll;" title="user req" [innerHTML]="stext"></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="test()">Save</button>
      </div>
    </div>
  </div>
  </div>


<!-- brd -->
<div class="modal fade" id="myModal1" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel">API doc</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="input-group input-group-lg">
          <textarea style="width:100%;    height: 300px; background-color:#181515;color:#ffffff;
          resize: none;
       overflow-y: scroll;" title="api" [(ngModel)]="stext1"></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="test()">Save</button>
      </div>
    </div>
  </div>
  </div>
