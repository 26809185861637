<div class="fine-tune-model-container" style="background-color: #EEF6F9; overflow-y: scroll;">
    <div class="container">
        <!-- <p class="code-enhance-header">Code documentation increases team efficiency by automating code documentation, facilitating quicker
            onboarding and collaboration. Reduce costs related to time-consuming manual documentation efforts.</p> -->
      <!-- <div class="row mt-4" *ngIf="filestatus">

        <div class="col-lg-12">
           <h5>User Requirements</h5>
          <div class="uploaded-file">
            <div >
                 <h5 class="uploaded-header">Uploaded file</h5>
                <div class="uploaded-file-section">
                    <div class="upload-file-name">
                        <img src="../../../../../assets/images/codeDocument.png" alt="">
                    <span tooltip="user requirement doc" class="file-font">{{ docname1 ? docname1 : 'User doc' }}</span>
                    </div>
                    <span class="action-buttons">
                      <input type="file" (change)="handleFileChange1($event)" #fileInput1 style="display: none;" accept=".doc,.txt,.docx">

                      <img tooltip="only .txt,.doc,.docx are allowed" src="../../../../../assets/images/uploadBlack.png" alt="" (click)="fileInput1.click()">
      </span>
                <span class="action-buttons">
                    <img tooltip="edit document text" src="../../../../../assets/images/refresh.png" alt="" data-toggle="modal" data-target="#myModal" >
                     /<img src="../../../../../assets/images/delete.png" alt="">

                  </span>
                </div>
            </div>
        </div>

           <div class="input-group input-group-lg">
            <textarea placeholder="Enter user requirement" [(ngModel)]="squery" [ngModelOptions]="{ standalone: true }" (change)="setquery()" type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"></textarea>
          </div>
        </div>

      </div> -->
      <br>
      <br>
      <div class="form-box" *ngIf="!(fileUploadedStatus || downloadStatus)">

        <div class="row gx-2 gy-2">
          <div class="csol" style="padding-left: 15px;">
            <p class="upload-label" class="astreik">Project Name:</p>

              <textarea [(ngModel)]="processName" [ngModelOptions]="{ standalone: true }"
              style="resize:none;height:50%; width:100%;" rows="4"
              placeholder="Please provide a concise and descriptive name for the process that accurately reflects its purpose and function."
              name="processName" #processNameField="ngModel" required minlength="5"  maxlength="255" ></textarea>

              <div class="text-danger">
                <!-- <small *ngIf="processNameField.errors?.required">Process name is required.</small> -->
                <small *ngIf="processNameField.errors?.['minlength']">Project Name cannot be less than 5 and cannot be greater than 255.</small>
                <small *ngIf="processNameField.errors?.['maxlength']">Project Name cannot be greater than 255.</small>
                        <!-- <small *ngIf="processName.length >= 5">
                          Project Name cannot be greater than 50.
                        </small> -->
              </div>

          </div>
          </div>
          <!-- add a dropdown -->
          <br>

<div >
          <div class="row gx-2 gy-2" >
            <div class="csol" style="padding-left:15px;">
              <p class="upload-label" class="astreik">Document Type</p>
              <div  style="background-image: linear-gradient(to bottom, #ffffff, #ffffff)">

              <ng-multiselect-dropdown [placeholder]="'Select'" [settings]="dropdownSettings"
              [data]="tcFiles1" [(ngModel)]="filetype" title=""
              >
            </ng-multiselect-dropdown>
            </div>
            </div>
          </div>
          <br>
          <div class="row gx-2 gy-2" *ngIf = "isFileNeeded">
            <div class="csol" style="padding-left:15px;">
              <p class="upload-label" class="astreik">Select File reference:</p>
              <div  style="background-image: linear-gradient(to bottom, #ffffff, #ffffff)">

              <ng-multiselect-dropdown [placeholder]="'Select'" [settings]="dropdownSettings"
              [data]="tcFiles" [(ngModel)]="tcSelected" title=""
              >
            </ng-multiselect-dropdown>
            </div>
            </div>
          </div>

      <div id="text-d" class="row mt-4">
        <div *ngIf="!(fileUploadedStatus || downloadStatus)">
        <div class="col-lg-12">
          <div class="uploaded-file">
            <div >
                <!-- <h5 class="uploaded-header">Uploaded file</h5> -->
                <div class="uploaded-file-section"  >
                    <div class="upload-file-name">
                        <img src="../../../../../assets/images/codeDocument.png" alt="">
                    <span  class="file-font">{{  docname ? docname : 'SDD/RA doc' }}
                    </span>
                    </div>
                    <span  class="action-buttons">
                      <input type="file" (change)="postUpload($event)" #fileInput style="display: none;" accept=".docx">
                      <button [disabled]="!( processName && processName.length >= 5)" (click)="fileInput.click()" tooltip="Only .docx is allowed,Project Name and Document type are Mandatory">
                        <img src="../../../../../assets/images/uploadBlack.png" alt="Upload .docx">
                      </button>
                    </span>

                </div>
            </div>
        </div>
      </div>
      <div class="py-2 pl-1 checks">
        <!-- <label class="checkbox-label checkboxx">
          <input type="checkbox" id="uploadCheckbox" [(ngModel)]="isFileNotNeeded" [ngModelOptions]="{standalone: true}" required [checked]="isFileNotNeeded">
          No file reference
        </label> -->
        <label class="checkbox-label checkboxx">
          <input type="checkbox" id="uploadCheckbox"  required [(ngModel)]="isFileNeeded" [ngModelOptions]="{standalone: true}" [checked]="isFileNeeded">
            File reference
        </label>
      </div>
</div>













      <!-- <div *ngIf = "isFileNeeded" class="row justify-content-center">
        <div class="col-md-3 mx-md-3 card py-3" style="background-image: linear-gradient(to bottom, #F8F8F8, #F0F0F0)">
          <ng-multiselect-dropdown [placeholder]="'Select'" [settings]="dropdownSettings"
          [data]="tcFiles" [(ngModel)]="tcSelected" title=""
          >
        </ng-multiselect-dropdown>
        </div>
        </div> -->
      </div>
      </div>
      </div>

        <div id="text-d" class="row" *ngIf="fileUploadedStatus || downloadStatus">
          <div class="container mt-4">
            <div class="overflow-auto text-center">
              <h5>Summary of {{filetype[0].value}} document</h5>
                            <!--  markdown lineNumbers -->
              <div *ngIf="rasheet"  style="height:350px;overflow-y: scroll; resize: both; width:100%;" markdown lineNumbers [data]="testanswer"></div>
              <textarea  *ngIf="!rasheet" style="height:350px;overflow-y: scroll; resize: both; width:100%;" title="tc" [(ngModel)]="testanswer">
              </textarea>
              </div>
          </div>
          </div>
        <div id="text-d" class="row" *ngIf="fileUploadedStatus || downloadStatus">
          <div class="container mt-4">
            <div class="overflow-auto text-center">
        <button tooltip="Edit Summary" *ngIf="!saverasheet" class="BRD-btn" (click)="edit()" style="margin-right: 50px;">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
          </svg> -->
          Edit Summary
        </button>
        <button tooltip="Save Summary" *ngIf="saverasheet" class="BRD-btn" (click)="save()" style="margin-right: 50px;">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-floppy2-fill" viewBox="0 0 16 16">
            <path d="M12 2h-2v3h2z"/>
            <path d="M1.5 0A1.5 1.5 0 0 0 0 1.5v13A1.5 1.5 0 0 0 1.5 16h13a1.5 1.5 0 0 0 1.5-1.5V2.914a1.5 1.5 0 0 0-.44-1.06L14.147.439A1.5 1.5 0 0 0 13.086 0zM4 6a1 1 0 0 1-1-1V1h10v4a1 1 0 0 1-1 1zM3 9h10a1 1 0 0 1 1 1v5H2v-5a1 1 0 0 1 1-1"/>
          </svg> -->
          Save Summary
        </button>
        <button tooltip="generate testcase"  (click)="generatetestcase1()"  style="margin-right: 50px;" class="BRD-btn">
      Generate Testcase
        </button>
        <button tooltip="Download Testcase" class="BRD-btn" *ngIf="downloadbtn"
        (click)="downloadList()">
        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
        </svg> -->
          Download Testcase
        </button>
        </div>
          </div>
          </div>


            <!-- <div class="text-right" *ngIf="filestatus1">
              <input type="file" (change)="handleFileChange($event)" #fileInput style="display: none;" accept=".doc,.txt">
              <button tooltip="Upload files" style="border:none;" (click)="fileInput.click()" type="button" class="btn btn-primary">
                <span class="button-text">Upload BRD</span>
              </button>
            </div> -->
          </div>
        </div>



