<!-- Header -->  
<header class="header">  
  <div class="container">  
    <div class="inner-header">  
      <!-- Left Header -->  
      <div class="left-sub-header">  
        <img src="../../../../../assets/logos.png" alt="logo">  
        <img src="../../../../../assets/CGL/images/logo.png" alt="logo">

      </div>  
  
      <!-- Middle Header -->  
      <nav class="middle-sub-header">  
        <ul class="menu">  
          <li class="sub-menu">  
            <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=IIrvvayqgE-zoNmjL5n9M90e9cRW2ZJNt3MEN5UWXmJUNDBYN1lPTzI4R1VYNUQ4RDVVMk9UMFBMNS4u" target="_blank">  
              Feedback  
            </a>  
          </li>  
          <li class="sub-menu">  
            <a href="https://genpactonline.sharepoint.com/:f:/s/IT/En9OkLY6kuFOowWGJj-pGnoBh_9YDVA55_NWG_recIAuag" target="_blank">  
              Templates  
            </a>  
          </li>  
          <li class="sub-menu">  
            <a href="https://genpactonline.sharepoint.com/:b:/s/IT/EWLunTNTtGJFqVlhXdOsUK8BpEEdrK4sz_iyLuY-4GGoVA?e=YudkNE" target="_blank">  
              SOP  
            </a>  
          </li>  
        </ul>  
      </nav>  
  
      <!-- Right Header -->  
      <div class="right-sub-header">  
        <!-- AI Chat Button -->  
        <div class="ai-chat-button-container">  
          <button class="ai-chat-button" (click)="toggleChat()">  
            <embed src="assets/CGL/images/stars.png" width="30" height="30" type="image/svg+xml" />  
            AI Assist  
          </button>  
        </div>  
  
        <!-- User Settings -->  
        <div class="user-setting">  
          <button class="user-setting-btn" [matMenuTriggerFor]="menu">  
            <div class="menuUserIcon">  
              <a>{{username}}</a>  
              <span><i class="fa fa-caret-down" aria-hidden="true"></i></span>  
            </div>  
          </button>  
          <mat-menu #menu="matMenu" class="background-color" style="padding: 10px;
          background: #073262;
          color: #ffffff;">
            <li style="border-bottom: 1px solid #ffffff;"  class="list-item item-first"><a style="padding: 5px;" class="nav-link" ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-door-fill" viewBox="0 0 16 16" style="margin-right: 10px;">
              <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5"/>
            </svg>   Home</a></li>
            
            <li class="list-item" (click)="exitApp()"><i class="fa-solid fa-right-from-bracket"></i>Exit application</li>
        </mat-menu> 
        </div>  
      </div>  
    </div>  
  </div>  
</header>  
  
<!-- Main Content -->  
<main class="main-content">  
  <div class="hero">  
    <img src="../../../../../assets/CGL/images/aboutbanner.png" alt="About Banner">  
  </div>  
  
  <h2>Key Features</h2>  
  <div class="features">  
    <div  
      class="feature"  
      *ngFor="let feature of features"  
      (click)="navigateTo(feature.link)"  
      role="button"  
      tabindex="0"  
      (keyup.enter)="navigateTo(feature.link)"  
      [attr.aria-label]="feature.title"  
    >  
        
      <h3>
        <img [src]="feature.image" [alt]="feature.title" />
        {{ feature.title }}</h3>  
      <p>{{ feature.description }}</p>  
    </div>  
  </div>  
</main>  
  
<!-- Footer -->  
<footer class="home-footer">  
  <div class="relentless">  
    <div class="container">  
      <div class="logo">  
        <img src="../../../assets/images/genpact-vertical-logo.svg" alt="Genpact Logo" />  
      </div>  
      <div class="relentless-font-style">  
        <p>  
          The relentless pursuit of a world that works  
          <span>better for </span><span>people</span>  
        </p>  
        <img src="../../../assets/images/underline-white.svg" alt="Underline" />  
      </div>  
    </div>  
  </div>  
  <div class="footer">  
    <div class="container">  
      <p>©2024 All rights reserved - Genpact.</p>  
      <div class="left-social">  
        <p>Follow us on social media:</p>  
        <ul class="social">  
          <li><a href="https://www.facebook.com/ProudToBeGenpact/" target="_blank"><i class="fab fa-facebook-square"></i></a></li>  
          <li><a href="https://twitter.com/genpact" target="_blank"><img src="../../../../assets/images/newTwitterIcon.png" alt="Twitter"></a></li>  
          <li><a href="https://www.linkedin.com/company/210064" target="_blank"><i class="fab fa-linkedin"></i></a></li>  
          <li><a href="https://www.youtube.com/genpactltd" target="_blank"><i class="fab fa-youtube"></i></a></li>  
        </ul>  
      </div>  
    </div>  
  </div>  
</footer>  