import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class InterAssistService implements HttpInterceptor {
  endpoint = 'https://api.playground-dev.genpact.com/hackathon';
  //  endpoint = 'https://gapiw.playground-dev.genpact.com/bgcheck';
    //endpoint ='https://gapiw.playground-dev.viren.in/bgc';
    endpoint1 = 'https://m3po2fpqgj.execute-api.ap-south-1.amazonaws.com/dev';
    accesspoint = environment.nodeEndpoint;
    endpoint2 =' https://api.playground-dev.genpact.com/';
    endpoint3 = "https://gapi1.playground.genpact.com/";


   
      
      

  constructor(private http: HttpClient) { }

  userList(): Observable<any> {
  return this.http.get(`${this.endpoint}/get-details`);
}

createuser(payload:any){
  return this.http.post(`${this.endpoint}/create`,payload)
}

uploadcvjd(payload:any){
  return this.http.post(`${this.endpoint}/upload_cv_jd`,payload)
}
 changestatus(payload:any){
  return this.http.post(`${this.endpoint}/change-status`,payload)
 }
qanda(payload:any){
  return this.http.post(`${this.endpoint}/generate-questions`,payload)
}
cvsummary(payload:any){
  return this.http.post(`${this.endpoint}/generate-cv-summary`,payload)
}
jdsummary(payload:any){
  return this.http.post(`${this.endpoint}/generate-jd-summary`,payload)
}

getskills(payload:any){
  return this.http.post(`${this.endpoint}/get-skills`,payload)
}
getquestions(payload:any){
  return this.http.post(`${this.endpoint}/get-questions`,payload)
}
gettranscript(payload:any){
  return this.http.post(`${this.endpoint}/get-transcript`,payload)
}
downloadTranscript(payload:any){
  return this.http.post(`${this.endpoint}/download-transcript-file`,payload)
}
generateReport(payload:any){
  return this.http.post(`${this.endpoint}/generate-report`,payload)
}
downloadReport(payload:any){
  return this.http.post(`${this.endpoint}/download-report-file`,payload)
}

// downloadbrd(payload: any): Observable<any> {
//   return this.http.post(`${this.apiURL}/devops/brd_document_status`, payload,
//   {
//       responseType: 'blob'
//     });
// }
downloadTranscript1(payload: any): Observable<any> {
  return this.http.post(`${this.endpoint}/download-transcript-file`, payload,
  {
      responseType: 'blob'
    });
}

savereport(payload:any){
  return this.http.post(`${this.endpoint}/save-report`,payload);
}
getreport(payload:any){
  return this.http.post(`${this.endpoint}/get-report`,payload,
  { responseType: 'blob'

  });
}

chatbot(payload:any){
  return this.http.post(`${this.endpoint}/chatbot`,payload)
}

uploadbyod(payload:any){
  return this.http.post(`${this.endpoint2}/uploader`,payload)
}
byodstatus(payload:any){
  return this.http.post(`${this.endpoint2}/byod-status`,payload)
}
getanswer(payload:any){
  return this.http.post(`${this.endpoint2}/getanswer`,payload)
}

cvjdmatch(payload: FormData): Observable<any> {
  return this.http.post(`${this.endpoint}/cvjdmatch`, payload);
}

verifyid(payload: FormData): Observable<any>{
  return this.http.post(`${this.endpoint}/verify-id`, payload)
}

verifyface(payload:FormData): Observable<any>{
  return this.http.post(`${this.endpoint}/verify-face`, payload)
}

verifyphotoazure(payload:FormData): Observable<any>{
  return this.http.post(`${this.endpoint}/verify-face-new`, payload)
}

verifyphotogcp(payload: FormData): Observable<any> {
  const promise = this.getDataAsPromise1(`${this.endpoint3}face-compare`, { method: 'POST', body: payload });
  return from(promise);
}


getDataAsPromise1(url: string, data: any = { method: 'POST' }, headers: any = {}): Promise<any> {
  let token: any = localStorage.getItem('token');
  headers = {
    ...headers,
    Authorization: 'Bearer ' + token,
  };
  
  return new Promise((resolve, reject) => {
    fetch(url, { ...data, headers })
      .then(response => response.json())
      .then(result => resolve(result))
      .catch(error => resolve(error));
  });
}

intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  const token = localStorage.getItem('token');

  if (token && req.url.includes('id')) {
    // Clone the request to add the Authorization header
    const cloned = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
    return next.handle(cloned);
  }

  // If no token or URL doesn't match, pass the request as is
  return next.handle(req);
}

}